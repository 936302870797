var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cyui-layout-wrapper"
  }, [_c('cyui-layout', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_vm.isCustomHeader ? _c('cyui-header', {
          attrs: {
            "title": _vm.routeMetaTitle
          },
          on: {
            "go-back": _vm.goBack
          }
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "default",
      fn: function () {
        return [_c('div', {
          staticClass: "page"
        }, [_c('van-cell-group', {
          staticClass: "cell-group"
        }, [_c('van-cell', {
          attrs: {
            "title": "调入申请员",
            "value": _vm.inApplicant
          }
        }), _c('van-cell', {
          attrs: {
            "title": "申请时间",
            "value": _vm.inTime
          }
        }), _vm.reviewStatus > 1 ? _c('van-cell', {
          attrs: {
            "title": "调入项目",
            "value": _vm.projectName
          }
        }) : _vm._e()], 1), _c('div', {
          staticClass: "list"
        }, _vm._l(_vm.productList, function (item, index) {
          return _c('div', {
            key: index,
            staticClass: "item"
          }, [_c('div', {
            staticClass: "title"
          }, [_c('span', [_vm._v("申请明细：第" + _vm._s(index + 1) + "条")])]), _c('van-cell-group', {
            staticClass: "cell-group"
          }, [_c('van-cell', {
            attrs: {
              "title": "资产属性",
              "value": _vm.productPropertyMap[item.productProperty] || '-'
            }
          }), _c('van-cell', {
            attrs: {
              "title": "资产名称",
              "value": item.productName || '-'
            }
          }), _c('van-cell', {
            attrs: {
              "title": "主规格",
              "value": item.standard || '-'
            }
          }), _c('van-cell', {
            attrs: {
              "title": "单位",
              "value": item.unit || '-'
            }
          }), _c('van-cell', {
            attrs: {
              "title": "申请数量",
              "value": item.applicationNum || '-'
            }
          })], 1)], 1);
        }), 0), _c('van-cell-group', {
          staticClass: "cell-group"
        }, [_c('van-cell', {
          attrs: {
            "title": "调出项目",
            "value": _vm.outProjectName || '-'
          }
        }), _c('van-cell', {
          attrs: {
            "title": "调出管理员",
            "value": _vm.outKeeper || '-'
          }
        }), _c('van-field', {
          staticClass: "vtextarea",
          attrs: {
            "type": "textarea",
            "readonly": "",
            "label": "说明",
            "rows": "3",
            "autosize": "",
            "input-align": "left"
          },
          model: {
            value: _vm.remark,
            callback: function ($$v) {
              _vm.remark = $$v;
            },
            expression: "remark"
          }
        }), _c('van-cell', {
          attrs: {
            "title": "状态"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_vm.reviewStatus == 1 ? _c('span', [_vm._v("未处理")]) : _vm.reviewStatus == 2 ? _c('span', [_vm._v("已驳回")]) : _vm.reviewStatus == 3 && _vm.allocateStatus == 0 ? _c('span', [_vm._v("审批通过")]) : _vm.reviewStatus == 3 && _vm.allocateStatus == 1 ? _c('span', [_vm._v("审批通过并已调拨出库")]) : _vm.reviewStatus == 4 ? _c('span', [_vm._v("已撤回")]) : _vm._e()];
            },
            proxy: true
          }])
        })], 1)], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function () {
        return [_vm.from == 'other' && _vm.reviewStatus == 1 ? _c('div', {
          staticClass: "footer"
        }, [_c('van-button', {
          staticStyle: {
            "margin-bottom": "10px"
          },
          attrs: {
            "block": "",
            "type": "primary",
            "size": "large"
          },
          on: {
            "click": _vm.onPass
          }
        }, [_vm._v("同意")]), _c('van-button', {
          attrs: {
            "block": "",
            "type": "danger",
            "size": "large"
          },
          on: {
            "click": _vm.onReject
          }
        }, [_vm._v("驳回")])], 1) : _vm._e()];
      },
      proxy: true
    }])
  }), _c('van-dialog', {
    attrs: {
      "title": "驳回",
      "show-cancel-button": "",
      "before-close": _vm.onRejectConfirm
    },
    model: {
      value: _vm.showReject,
      callback: function ($$v) {
        _vm.showReject = $$v;
      },
      expression: "showReject"
    }
  }, [_c('div', {
    staticClass: "rejectPanel"
  }, [_c('div', {
    staticClass: "tip"
  }, [_vm._v("您驳回了调拨申请，请填写驳回原因 ")]), _c('van-field', {
    staticClass: "vtextarea",
    attrs: {
      "label": "原因",
      "required": "",
      "type": "textarea",
      "rows": "3",
      "autosize": "",
      "maxlength": "200",
      "placeholder": "必填",
      "input-align": "left",
      "show-word-limit": ""
    },
    model: {
      value: _vm.rejectReason,
      callback: function ($$v) {
        _vm.rejectReason = $$v;
      },
      expression: "rejectReason"
    }
  })], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };