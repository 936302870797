import request from '@utils/request_gd';
export const fileUpload = (file = {}, config) => {
  let data = new FormData();
  data.append('file', file.file);
  return request.post('/file/upload', data);
};
export const fileUploadBase64 = base64Code => {
  return request.post('/material/api/file/saveBase64ToImg', {
    base64Code
  });
};
export const getIconList = () => {
  return request.get('/material/api/user/getIconList');
};
export const getSpaceListByRole = projectId => {
  return request.get('/material/api/v1/allocateApply/getSpaceListByRole', {
    params: {
      projectId
    }
  });
};
export const getOtherProjectCount = data => {
  return request.post('/material/api/v1/allocateApply/getOtherProjectCount', data);
};
export const getStockProductList = params => {
  return request.get('/material/api/stock/getListOfPage', {
    params
  });
};
export const getProjectStockProductList = params => {
  return request.get('/material/api/stock/getStockCountOfProjectId', {
    params
  });
};
export const getProductList = params => {
  return request.get('/material/api/product/getListOfPage', {
    params
  });
};

// 入库
export const getStockInOrderList = (params = {}) => {
  return request.get('/material/api/materialStockIn/getOrderList', {
    params
  });
};
export const getStockInDetailInit = (params = {}) => {
  return request.get('/material/api/materialStockIn/detailInit', {
    params
  });
};
export const inStock = data => {
  return request.post('/material/api/materialStockIn/inStock', data);
};
export const getStockInList = (params = {}) => {
  return request.get('/material/api/materialStockIn/getListOfPage', {
    params
  });
};
export const getStockInDetail = id => {
  return request.get('/material/api/materialStockIn/getById', {
    params: {
      id
    }
  });
};

//领用
export const stockAllocateApply = data => {
  return request.post('/material/api/v1/allocateApply/add', data);
};
export const getAllocateApplyListCurrent = data => {
  return request.post('/material/api/v1/allocateApply/getPageOfCurrentProject', data);
};
export const revokeAllocateApply = data => {
  return request.post('/material/api/v1/allocateApply/revoke', data);
};
export const getAllocateApplyListOther = data => {
  return request.post('/material/api/v1/allocateApply/getPageOfOtherProject', data);
};
export const getAllocateApplyDetail = allocateApplyId => {
  return request.post('/material/api/v1/allocateApply/getDetail', {
    allocateApplyId
  });
};
export const passAllocateApply = data => {
  return request.post('/material/api/v1/allocateApply/pass', data);
};
export const rejectAllocateApply = data => {
  return request.post('/material/api/v1/allocateApply/reject', data);
};

// 调拨
export const getOutAllocateApplyList = data => {
  return request.post('/material/api/v1/allocateOut/getAllocateApplyList', data);
};
export const getOutAllocateApplyDetail = allocateApplyId => {
  return request.post('/material/api/v1/allocateOut/getAllocateApplyDetail', {
    allocateApplyId
  });
};
export const addOutAllocateApply = data => {
  return request.post('/material/api/v1/allocateOut/add', data);
};
export const getAllocateOutList = data => {
  return request.post('/material/api/v1/allocateOut/getPageOfRecord', data);
};
export const getAllocateOutDetail = allocateOutId => {
  return request.post('/material/api/v1/allocateOut/getDetail', {
    allocateOutId
  });
};

// 领用
export const getTakeList = data => {
  return request.post('/material/api/take/getTakeList', data);
};
export const getTakeDetail = id => {
  return request.post('/material/api/take/getDetail', {
    id
  });
};
export const getTakeSpaceList = projectId => {
  return request.get('/material/api/take/getSpaceList', {
    params: {
      projectId
    }
  });
};
export const addTake = data => {
  return request.post('/material/api/take/add', data);
};

// 处置
export const addDispose = data => {
  return request.post('/material/api/dispose/add', data);
};
export const getDisposeList = data => {
  return request.post('/material/api/dispose/getDisposeList', data);
};
export const getDisposeDetail = id => {
  return request.post('/material/api/dispose/getDetail', {
    id
  });
};

// 盘点
export const getAllCangKuGongQuByProjectId = projectId => {
  return request.get('/material/api/space/getAllCangKuGongQuByProjectId', {
    params: {
      projectId
    }
  });
};
export const getAllSpaceByProjectId = projectId => {
  return request.get('/material/api/space/getAllSpaceByProjectId', {
    params: {
      projectId
    }
  });
};
export const getCheckOrInit = data => {
  return request.post('/material/api/materialCheck/getOrInit', data);
};
export const saveCheck = data => {
  return request.post('/material/api/materialCheck/saveCheck', data);
};
export const getCheckList = params => {
  return request.get('/material/api/materialCheck/getListOfPage', {
    params
  });
};
export const getCheckDetail = id => {
  return request.get('/material/api/materialCheck/getById', {
    params: {
      id
    }
  });
};
export const commitCheck = data => {
  return request.post('/material/api/materialCheck/modify', data);
};
export const getNewGdCheckDetailList = id => {
  return request.get('/material/api/materialCheck/getNewGdCheckDetailList', {
    params: {
      id
    }
  });
};
export const addCheckAdditional = data => {
  return request.post('/material/api/materialCheck/addAdditional', data);
};
export const getDingDeptList = () => {
  return request.get('/material/api/materialCheck/getDingDeptList');
};
export const modifyCheckNum = data => {
  return request.post('/material/api/materialCheck/modifyCheckNum', data);
};
export const getStandardDesList = params => {
  return request.get('/material/api/selectOption/getList', {
    params
  });
};
export const addStandardDes = data => {
  return request.post('/material/api/selectOption/add', data);
};
export const getAddList = data => {
  return request.post('/material/api/materialAdd/getListOfPage', data);
};
export const addAsset = data => {
  return request.post('/material/api/materialAdd/addMaterial', data);
};