import _keyBy from "lodash/keyBy";
import _mapValues from "lodash/mapValues";
import common from '@mixins/common';
import { mapGetters } from 'vuex';
import * as assetsApi from '@api/assets';
const productPropertyList = [{
  label: "易耗品",
  value: 1
}, {
  label: "低值品",
  value: 2
}, {
  label: "固定资产",
  value: 3
}];
const productPropertyMap = _mapValues(_keyBy(productPropertyList, i => i.value), j => j.label);
export default {
  name: 'AssetsDispatchApplyDetail',
  mixins: [common],
  computed: {
    ...mapGetters('user', ['userInfo', 'projectInfo'])
  },
  data() {
    return {
      from: '',
      allocateApplyId: '',
      inApplicant: '',
      inTime: '',
      projectName: '',
      productList: [],
      outProjectName: '',
      outKeeper: '',
      outSpaceProjectId: '',
      remark: '',
      reviewStatus: '',
      allocateStatus: '',
      productPropertyMap,
      showReject: false,
      rejectReason: ''
    };
  },
  created() {
    this.from = this.$route.query.from;
    this.allocateApplyId = this.$route.query.allocateApplyId;
    this.getData();
  },
  methods: {
    getData() {
      assetsApi.getAllocateApplyDetail(this.allocateApplyId).then(res => {
        let item = res.data.data.result;
        this.inApplicant = item.inApplicant;
        this.inTime = item.applicationDate;
        this.projectName = item.projectName;
        this.productList = item.productList;
        this.outProjectName = item.outProjectName;
        this.outKeeper = item.outKeeper;
        this.remark = item.remark;
        this.reviewStatus = item.reviewStatus;
        this.allocateStatus = item.allocateStatus;
      });
    },
    onPass() {
      this.$dialog.confirm({
        message: '请确认是否同意该调拨申请？'
      }).then(() => {
        assetsApi.passAllocateApply({
          allocateApplyId: this.allocateApplyId
        }).then(res => {
          this.$toast('提交成功');
          this.$router.back();
        });
      }).catch(() => {
        // on cancel
      });
    },
    onReject() {
      this.rejectReason = '';
      this.showReject = true;
    },
    onRejectConfirm(action, done) {
      if (action === 'confirm') {
        if (!this.rejectReason) {
          this.$toast('请填写原因');
          done(false);
          return;
        }
        assetsApi.rejectAllocateApply({
          allocateApplyId: this.allocateApplyId,
          reason: this.rejectReason
        }).then(res => {
          done();
          this.$toast('提交成功');
          this.$router.back();
        }).catch(e => {
          done(false);
        });
      } else {
        done();
      }
    }
  }
};